.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  margin-bottom: 0px;
}

h2 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: 'Verdana';
}

h5 {
  margin-top: 10px;
  margin-bottom: 0px;
}

h6 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.body {
  background-color: #282c34;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

button {
  border: none;
  color: white;
  padding: 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 15px 15px;
  margin-bottom: 20px;
  border-radius: 8px;
  font-size: 20px;
  color: white;
}

.generate {
  background-color: #d97d0c;
  border: 3px solid #d98f35;
}

.download {
  background-color: #6553cd;
  border: 3px solid #7e71cd;
}

input {
  margin-top: 20px;
}

.input-caption {
  max-width: 170px;
}

label {
  padding: 5px;
  padding-top: 10px;
  margin-right: 10px;
  margin-top: 10px;
  font-size: 15pt;
}

#myCanvas {
  max-width: 1000;
  max-height: 1000;
}

.checkbox-container {
  margin-bottom: 20px;
  margin-top: 20px;
  padding-bottom: 10px;
}

.checkbox-item {
  display: flex;
  align-items: center;
  margin-left: 70px;
}

.catergories-label {
  margin-left: 70px;
  margin-right: 70px;
}

#size-input {
  width: 170px;
}

.field-label {
  margin-right: 5px;
  margin-left: 5px;
}

.input-field-div {
  margin-top: 10px;
  margin-bottom: 10px;
}

.input-field-box {
  border: 3px solid #3c424f;
  border-radius: 5px;
  padding: 5px;
}

.size-input {
  margin-top: 0px;
}

input {
  color: #000000;
  height: 28px;
  padding-left: 10px;
  text-decoration: none;
  background-repeat: repeat-x;
  border-radius: 5px;
  border: 0;
}

input[type="checkbox"] {
  position: relative;
  display: block;
  width: 20px;
  height: 20px;
  border: 1px solid #808080;
  border-radius: 5px;
  content: "";
  background: #FFF;
}

select {
  color: #000000;
  height: 28px;
  padding-left: 10px;
  text-decoration: none;
  background-repeat: repeat-x;
  border-radius: 5px;
  border: 0;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th {
  text-align: right;
  padding: 8px;
  vertical-align: middle;
}

td {
  padding: 8px;
  vertical-align: middle;
}

input,
select {
  width: 100%;
}

.popupContentStyle {
  background-color: 'white';
  padding: '20px';
  border-radius: '8px';
  text-align: 'center';
  max-width: '400px';
  width: '80%';
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: rgb(95, 92, 92);
  color: white;
  padding: 20px;
  border-radius: 8px;
  position: relative;
  width: 300px;
  max-width: 80%;
  border: 5px solid #3c3c3c;
}

.stay-button {
  width: 120px;
  background-color: green;
  border: 3px solid #0c4913;
}

.leave-button {
  width: 120px;
  background-color: red;
  border: 3px solid #860c0c;
}